import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../index.css';

const windowsLogo = 'images/windows.png';
const macLogo = 'images/apple.png';
const linuxLogo = 'images/linux.png';

const HomePage = () => {
  const [versions, setVersions] = useState({ windows: [], mac: [], linux: [] });

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get('https://api.github.com/repos/fiftysixcrypto/nodevin/releases');
        const releases = response.data;

        const versionData = {
          windows: [],
          mac: [],
          linux: []
        };

        releases.forEach(release => {
          const version = release.tag_name;

          release.assets.forEach(asset => {
            if (asset.name.includes('windows')) {
              versionData.windows.push({ version, url: asset.browser_download_url });
            } else if (asset.name.includes('mac')) {
              versionData.mac.push({ version, url: asset.browser_download_url });
            } else if (asset.name.includes('linux')) {
              versionData.linux.push({ version, url: asset.browser_download_url });
            }
          });
        });

        setVersions(versionData);
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);

  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <h1>nodevin</h1>
          <p>We developed nodevin so you could support decentralization.</p>
          <div className='App-sub-header-text'>
            <p>nodevin is a CLI and daemon that starts and manages blockchain nodes. <a rel="noopener noreferrer" target="_blank" href="https://github.com/fiftysixcrypto/nodevin">Code here.</a></p>
          </div>
        </header>
        <main>
          <section className="latest-versions">
            <div className="os-box">
              <img src={windowsLogo} alt="Windows" />
              <h2>Windows</h2>
              <p>Latest Version: {versions.windows[0]?.version}</p>
              <a href={versions.windows[0]?.url} target="_blank" rel="noopener noreferrer">Download</a>
            </div>
            <div className="os-box">
              <img src={macLogo} alt="Mac" />
              <h2>Mac</h2>
              <p>Latest Version: {versions.mac[0]?.version}</p>
              <a href={versions.mac[0]?.url} target="_blank" rel="noopener noreferrer">Download</a>
            </div>
            <div className="os-box">
              <img src={linuxLogo} alt="Linux" />
              <h2>Linux</h2>
              <p>Latest Version: {versions.linux[0]?.version}</p>
              <a href={versions.linux[0]?.url} target="_blank" rel="noopener noreferrer">Download</a>
            </div>
          </section>
          <section>
            <div>
              <div className="wrapper">
                <div className="support-container">
                  <div className="logo-container">
                    <div className="logo-fest">
                      <img src="images/bitcoin-logo.png" alt="Bitcoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/ethereum-logo.png" alt="Ethereum"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/litecoin-logo.png" alt="Litecoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/dogecoin-logo.png" alt="Dogecoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/ethereum-classic-logo.png" alt="Eth-Classic"/>
                    </div>
                </div>
              </div>
              </div>
            </div>
          </section>
          <section className="earlier-versions">
            {Object.keys(versions).map((os) => (
              <div key={os}>
                <h3>{os.charAt(0).toUpperCase() + os.slice(1)}</h3>
                {versions[os].slice(1).map((versionData, index) => (
                  <div key={versionData.version} className={`version-box ${index % 2 === 0 ? 'dark' : 'light'}`}>
                    <p>{versionData.version}</p>
                    <a href={versionData.url} target="_blank" rel="noopener noreferrer">Download</a>
                  </div>
                ))}
              </div>
            ))}
          </section>
          <br/>
          <div>
            <img className="nodevin-image" src="nodevin-icon.png" alt="nodevin-icon"/>
          </div>
          <br/>
          <p className="smaller-text"><em>Join our <a rel="noopener noreferrer" target="_blank" href="https://discord.com/invite/XuhW2ykW3D">Discord</a>.</em></p>
          <br/>
          <p>"The network is robust in its unstructured simplicity." -- Satoshi Nakamoto</p>
          <br/>
        </main>
      </div>
      <footer className="App-footer">
        <div className="container">
          <span>Brought to you by <strong><a href="https://fiftysix.xyz" rel="noopener noreferrer" target="_blank" className='App-footer-a'>Fiftysix</a></strong></span>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
