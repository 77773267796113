import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, HashRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomePage/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
